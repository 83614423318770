/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

import StyledCheck from '../checkboxes/StyledCheck';

/**
 * Used as a layout wrapper for other Toggle views. Accepts all `<div>` props
 */
const StyledToggle = StyledCheck;

StyledToggle.displayName = 'StyledToggle';

export default StyledToggle;
