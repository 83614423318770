/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */
/**
 * Convert Garden RTL aware placement to Popper.JS valid placement
 */
export function getPopperPlacement(gardenPlacement) {
    switch (gardenPlacement) {
        case 'end':
            return 'right';
        case 'end-top':
            return 'right-start';
        case 'end-bottom':
            return 'right-end';
        case 'start':
            return 'left';
        case 'start-top':
            return 'left-start';
        case 'start-bottom':
            return 'left-end';
        default:
            return gardenPlacement;
    }
}
/**
 * Convert Garden RTL aware placement to RTL equivalent Popper.JS placement
 * @param {String} gardenPlacement
 */
export function getRtlPopperPlacement(gardenPlacement) {
    const popperPlacement = getPopperPlacement(gardenPlacement);
    switch (popperPlacement) {
        case 'left':
            return 'right';
        case 'left-start':
            return 'right-start';
        case 'left-end':
            return 'right-end';
        case 'top-start':
            return 'top-end';
        case 'top-end':
            return 'top-start';
        case 'right':
            return 'left';
        case 'right-start':
            return 'left-start';
        case 'right-end':
            return 'left-end';
        case 'bottom-start':
            return 'bottom-end';
        case 'bottom-end':
            return 'bottom-start';
        default:
            return popperPlacement;
    }
}
